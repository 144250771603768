import { render, staticRenderFns } from "./PrivacyAndPolicyTitle.vue?vue&type=template&id=7d5047e1&scoped=true&"
var script = {}
import style0 from "./PrivacyAndPolicyTitle.vue?vue&type=style&index=0&id=7d5047e1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d5047e1",
  null
  
)

export default component.exports